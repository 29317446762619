export class ChangeOrderlineDueDateModel {
  OrderlineId!: string;
  Date!: string;
  IsARush!: boolean;
  RushFee!: number;
  DeliveryOptionId!: number;
  RushOptionId!: string;
  ShouldApplyToWholeOrder!: boolean;
}
export class ComboPricingModel {
  Id!: number;
  CustomerId!: number;
  DieId!: number;
  FinishId!: number;
  MaterialId!: number;
  ColorId!: number;
  ItemOrder!: number;
  EntryType!: number;
  QuantityThreshold!: number;
  PricePercentage!: number;
  Die!: string;
  Finish!: string;
  Material!: string;
  Color!: string;
  EntryState!: number;
  GroupKey!: string;
  isEditingQuantity!: boolean;
  isEditedQuantity!: boolean;
  isEditingPrice!: boolean;
  isEditedPrice!: boolean;
  isClonedEntry!: boolean;

  constructor(rawItem?: any) {
    if (rawItem) {
      this.Id = rawItem.Id;
      this.CustomerId = rawItem.CustomerId;
      this.DieId = rawItem.DieId;
      this.FinishId = rawItem.FinishId;
      this.MaterialId = rawItem.MaterialId;
      this.ColorId = rawItem.ColorId;
      this.ItemOrder = rawItem.ItemOrder;
      this.EntryType = rawItem.EntryType;
      this.QuantityThreshold = rawItem.QuantityThreshold;
      this.PricePercentage = rawItem.PricePercentage;
      this.Die = rawItem.Die;
      this.Finish = rawItem.Finish;
      this.Material = rawItem.Material;
      this.Color = rawItem.Color;
      this.isEditingPrice = false;
      this.isEditedPrice = false;
      this.isEditedQuantity = false;
      this.isEditingQuantity = false;
      this.isClonedEntry = false;
      this.GroupKey = rawItem.GroupKey;
    }
  }
}
export class ComboPricingGroup {
  GroupKey!: string;
  GroupState!: number;
  ItemCount!: number;
  TrueCost!: number;
  TotalShipped!: number;
  NumberOfLabelsPerFrame!: number;
  ComboPricingModels!: ComboPricingModel[];
  DieId!: number;
  constructor(rawItem?: any) {
    this.ComboPricingModels = [];
    if (rawItem) {
      this.GroupKey = rawItem.GroupKey;
      this.ItemCount = rawItem.ItemCount;
      this.TrueCost = rawItem.TrueCost;
      this.TotalShipped = rawItem.TotalShipped;
      this.GroupState = 0;
      this.NumberOfLabelsPerFrame = rawItem.NumberOfLabelsPerFrame;
      if (rawItem.ComboPricingModels) {
        this.ComboPricingModels = rawItem.ComboPricingModels.map(
          (c: any) => new ComboPricingModel(c)
        );
        this.DieId = this.ComboPricingModels[0].DieId;
      }
    }
  }
}
export class ComboPriceGenerationModel {
  CustomerId!: string;
  DieId!: number;
  FinishId!: number;
  MaterialId!: number;
  ColorId!: number;
  EntryType!: number;
  MininumQuantityThreshold!: number;
  MaximumQuantityThreshold!: number;
  StartingPricePercentage!: number;
  EndingPricePercentage!: number;
  NumberOfEntries!: number;
  ShouldUseDefaultValues!: boolean;
  constructor() {
    this.ShouldUseDefaultValues = true;
  }
}

export class AltPricingModel {
  Id!: number;
  Facestock!: string;
  MaterialId!: number;
  Lamination!: string;
  FinishId!: number;
  Die!: string;
  DieId!: number;
  Colors!: string;
  ColorId!: number;
  PriceEach!: number;
  NormalPriceEach!: number;
  CustomerId!: string;
  TotalItemCount!: number;
  TotalItemsOrdered!: number;
  Difference!: number;
  Cost!: number;
  QuoteQuantity!: number;
  constructor(rawItem?: any) {
    if (rawItem) {
      this.Id = rawItem.Id;
      this.Facestock = rawItem.Facestock;
      this.MaterialId = rawItem.MaterialId;
      this.Lamination = rawItem.Lamination;
      this.FinishId = rawItem.FinishId;
      this.Die = rawItem.Die;
      this.DieId = rawItem.DieId;
      this.Colors = rawItem.Colors;
      this.ColorId = rawItem.ColorId;
      this.PriceEach = rawItem.PriceEach;
      this.NormalPriceEach = rawItem.NormalPriceEach;
      this.CustomerId = rawItem.CustomerId;
      this.TotalItemCount = rawItem.TotalItemCount;
      this.Difference = this.NormalPriceEach - this.PriceEach;
      this.Cost = rawItem.CostEach;
      this.TotalItemsOrdered = rawItem.TotalItemsOrdered;
    }
  }
}
